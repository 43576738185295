var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary title-kalam"},[_vm._v(" Finance ")])]),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Start tracking team performance 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Create your account ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-form-group',{attrs:{"label":"First name","label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","state":errors.length > 0 ? false:null,"name":"register-firstname","placeholder":"John"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last name","label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","state":errors.length > 0 ? false:null,"name":"register-lastname","placeholder":"Mayer"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Repeat your password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" Sign up ")])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{name:'login'}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Back to login ")],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }